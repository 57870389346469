export default {
  loadPolicyScript() {
    return new Promise(resolve => {
      var loader = function() {
        var s = document.createElement("script"),
          tag = document.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
      };
      if (window.addEventListener) {
        window.addEventListener("load", loader, false);
      } else if (window.attachEvent) {
        window.attachEvent("onload", loader);
      } else {
        window.onload = loader;
      }
      resolve(true);
    });
  }
};
