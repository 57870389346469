<template>
  <div class="static-page" :class="page.slug" v-if="page">
    <Breadcrumb :items="breadcrumb" />
    <!-- <CategoryTitle :category="category" class="ma-7" /> -->
    <v-container>
      <page-title class="hidden-xs-only" :page="page" />
    </v-container>
    <component :is="templateName" :key="key" :page="page"></component>
    <ImgSlider
      v-if="this.page && this.page.slider_img"
      :imgs="this.page.slider_img"
    />
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import Static from "@/components/wordpress/Static.vue";
import PageTwoColumn from "@/components/wordpress/PageTwoColumn.vue";
import PageFullwidth from "@/components/wordpress/PageFullwidth.vue";
import PageFaq from "@/components/wordpress/PageFaq.vue";
import PageContacts from "@/components/wordpress/PageContacts.vue";
import PageProductRecall from "@/components/wordpress/PageProductRecall.vue";
import PageAssortment from "@/components/wordpress/PageAssortment.vue";
import ImgSlider from "@/components/wordpress/ImgSlider.vue";

import iubendaPolicy from "/public/js/iubenda-policy.js";

import page from "~/mixins/page";
import { mapGetters } from "vuex";

export default {
  async created() {
    if (this.$route.params.pageName === "cookie-policy") {
      this.fetchPage(this.$route.params.pageName);
      await iubendaPolicy.loadPolicyScript();
    } else {
      this.fetchPage(this.$route.params.pageName);
    }
  },
  mixins: [page],
  data() {
    return { key: 1 };
  },
  components: {
    Static,
    Breadcrumb,
    PageTwoColumn,
    PageFullwidth,
    ImgSlider,
    PageTitle,
    PageFaq,
    PageContacts,
    PageProductRecall,
    PageAssortment
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    templateName() {
      if (this.page.template) {
        // return trimEnd(this.page.template, ".php");
        return this.page.template.replace(".php", "");
      } else {
        return "Static";
      }
    }
  },
  watch: {
    "$route.params.pageName": function(pageName) {
      this.fetchPage(pageName);
    }
  }
};
</script>
