<template>
  <v-container @click="clicked">
    <v-runtime-template :template="template" />
  </v-container>
</template>
<script>
import clickHandler from "~/mixins/clickHandler";
import VRuntimeTemplate from "v-runtime-template";
export default {
  name: "Static",
  mixins: [clickHandler],
  props: { page: { type: Object, required: true } },
  data() {
    return {
      template: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate
  },
  // computed: {
  //   content() {
  //     return get(this.page, "content.rendered");
  //   }
  // },
  mounted() {
    this.$nextTick(() => {
      if (this.page && this.page.content) {
        this.template = `<div>${this.page.content.rendered}</div>`;
      }
    });
  }
};
</script>
