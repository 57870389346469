<template>
  <div class="faq">
    <!-- <v-container>
      <div v-html="content" @click="clicked"></div>
    </v-container> -->
    <v-container fluid>
      <v-row no-gutters>
        <v-col
          cols="0"
          md="3"
          class="pl-4 grey lighten-3"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div class="text-h6 primary--text font-weight-bold">
            FILTRA LE FAQ PER:
          </div>
          <FaqCategory class=" grey lighten-3" @filter="handleFilter" />
        </v-col>
        <v-col cols="12" md="9" class="position-relative">
          <div v-if="faqList != null">
            <v-expansion-panels accordion flat>
              <v-expansion-panel
                v-for="item in faqList"
                :key="item.id"
                class="mb-1"
              >
                <v-expansion-panel-header class="grey lighten-2 primary--text">
                  <h3 v-html="item.title.rendered"></h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.content.rendered"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-speed-dial
              v-if="$vuetify.breakpoint.mobile"
              v-model="fab"
              :right="true"
              :bottom="true"
              fixed
              direction="left"
              transition="slide-y-reverse-transition"
              class="faq-filter"
            >
              <template v-slot:activator>
                <v-btn v-model="fab" color="primary" fab>
                  <v-icon v-if="fab">
                    $close
                  </v-icon>
                  <v-icon v-else>
                    $tuneVariant
                  </v-icon>
                </v-btn>
              </template>
              <v-card><FaqCategory @filter="handleFilter" /> </v-card>
            </v-speed-dial>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.faq {
  .v-expansion-panel-header {
    border-radius: 0;
  }
  .v-expansion-panel-header__icon {
    i {
      color: var(--v-primary-base) !important;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 16px 24px;
    p {
      margin-bottom: 0;
    }
  }
}
.faq-filter {
  bottom: 120px !important;
  right: 8px !important;
  .v-speed-dial__list {
    min-width: 400px;
    left: initial;
    right: 0;
  }
}
</style>
<script>
import FaqCategory from "@/components/wordpress/FaqCategory";
import CMSService from "~/service/cmService";

import clickHandler from "~/mixins/clickHandler";

import get from "lodash/get";

export default {
  name: "PageFaq",
  components: { FaqCategory },

  data() {
    return {
      faqList: null,
      fab: false
    };
  },
  mixins: [clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    content() {
      return get(this.page, "content.rendered");
    }
  },
  mounted() {
    this.fetchFaqList(this.$route.params.categoryName);
  },
  methods: {
    async fetchFaqList(categoryName) {
      let res = await CMSService.getCustomPostByFilters("faq", [
        {
          filterName: "filter[categoria_faq]",
          filterValue: categoryName
        }
      ]);
      this.faqList = res.data;
    },
    handleFilter(slug) {
      this.fetchFaqList(slug);
    }
  },
  watch: {
    "$route.params.categoryName": function(categoryName) {
      this.fetchFaqList(categoryName);
    }
  }
};
</script>
