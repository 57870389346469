import { render, staticRenderFns } from "./PageProductRecall.vue?vue&type=template&id=a503bce6&scoped=true&"
import script from "./PageProductRecall.vue?vue&type=script&lang=js&"
export * from "./PageProductRecall.vue?vue&type=script&lang=js&"
import style0 from "./PageProductRecall.vue?vue&type=style&index=0&id=a503bce6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a503bce6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCol,VContainer,VList,VRow})
